import Image from 'next/image';
import { Stack, Typography } from '@mui/material';
import Button from '../Button';

interface EmptyStatusProps {
  title: string;
  subTitle?: string;
  buttonConfig?: {
    label: string;
    onClick: () => void;
  };
  imagePath?: string;
}

const EmptyStatus = ({
  title,
  subTitle,
  buttonConfig,
  imagePath,
}: EmptyStatusProps) => {
  return (
    <Stack width='100%' alignItems='center'>
      <Stack alignItems='center' spacing={1} py={20} maxWidth={320}>
        {imagePath && (
          <Image src={imagePath} alt='empty status' width={80} height={80} />
        )}
        <Typography variant='h3' color='text.tertiary' textAlign='center'>
          {title}
        </Typography>
        {subTitle && (
          <Typography variant='body1' color='text.tertiary' textAlign='center'>
            {subTitle}
          </Typography>
        )}
        {buttonConfig && (
          <Stack spacing={1}>
            <Button title={buttonConfig.label} onClick={buttonConfig.onClick} />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default EmptyStatus;
