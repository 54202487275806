import { useEffect, ReactNode, useCallback, useContext, useMemo } from 'react';
import dynamic from 'next/dynamic';
import LogRocket from 'logrocket';
import { useRouter } from 'next/router';
import { Routes } from '@madeinventive/core-types';

//components
import { Stack } from '@mui/material';
import Modals from '../modals';
import Header from '../Header';
import PoweredByRibbon from '../PoweredBy/Ribbon';
import ErrorBoundary from '../ErrorBoundary';

// hooks and context
import { useAppDispatch, useAppSelector, useSessionInfo } from '../../hooks';
import { setActiveModalSlice } from '../../store/slices/modal';
import { useEmbeddingContext } from '../../contexts/EmbeddingProvider';
import { EnvironmentContext } from '../EnvironmentProvider';
import WorkspacePageLayout from './WorkspaceLayout';
import EmptyState from '../shared/EmptyState';

// lazy load layouts
const SettingsTopBar = dynamic(
  () => import('./LayoutComponents/SettingsTopBar'),
);
const WorkspaceSelectorBar = dynamic(() => import('../WorkspaceSelectorBar'));

const SettingsSidebar = dynamic(
  () => import('./LayoutComponents/SettingsSidebar'),
);

interface LayoutProps {
  children?: ReactNode;
}

const RootLayout = ({ children }: LayoutProps) => {
  const router = useRouter();
  const storeDispatch = useAppDispatch();
  const { pathname } = useRouter();
  const { isEmbedded } = useEmbeddingContext();
  const { isSignedIn, userType, email, firstName, lastName } = useSessionInfo();
  const { environment } = useContext(EnvironmentContext);
  const workspaceId = useAppSelector(
    (state) => state.workspace.value?.workspace?.id,
  );

  const firstPath = pathname.split('/')[1];
  const isWorkspace = firstPath === 'workspace';
  const requireWorkspace =
    firstPath === 'workspace' || firstPath === 'workspace-settings';

  const showC1Settings =
    (!isEmbedded && userType === 'EnvironmentMember') ||
    userType === 'SystemUser';
  const shouldShowPoweredBy = useMemo(
    () => !environment?.whiteLabeled,
    [environment?.whiteLabeled],
  );

  const workspaceSelectionCallback = useCallback(
    (workspaceId: string) => {
      if (isWorkspace) {
        // if the page is workspace page, `workspace/[workspaceId]`,
        // we need to update the url
        router.replace(Routes.workspace(workspaceId), undefined, {
          shallow: true,
        });
      }
    },
    [isWorkspace, router],
  );

  useEffect(() => {
    if (isSignedIn) {
      LogRocket.identify(email, {
        name: `${firstName} ${lastName}`,
        email: email,
        userType: userType,
        env: process.env.NEXT_PUBLIC_ENV ?? 'local',
      });
    }
  }, [email, firstName, isSignedIn, lastName, userType]);

  // This useEffect can be deleted when the settings modal completed removed for C2 users
  useEffect(() => {
    // open settings modal if query param is present
    const openSettingsModal = () => {
      storeDispatch(
        setActiveModalSlice({
          activeModal: 'settings_gallery',
        }),
      );
    };

    if (router.query.settings) {
      openSettingsModal();
    }
  }, [router.query.settings, storeDispatch]);

  return (
    <>
      <Header />
      <Modals />
      <Stack id='view' height='100vh' overflow='hidden'>
        {showC1Settings && <SettingsTopBar />}
        <Stack
          id='organization-level-content-container'
          flex={1}
          overflow='hidden'
        >
          {showC1Settings && requireWorkspace && (
            <WorkspaceSelectorBar
              selectionCallback={workspaceSelectionCallback}
            />
          )}
          <Stack
            id='page-content-container'
            direction='row'
            flex={1}
            width='100%'
            overflow='hidden'
            position='relative'
          >
            <SettingsSidebar currentPath={pathname} />
            <Stack id='page-content' width='100%' overflow='auto' flex={1}>
              {requireWorkspace && !workspaceId ? (
                <EmptyState
                  title='No workspace selected'
                  subTitle='Choose a workspace from the list to get started.'
                />
              ) : (
                <ErrorBoundary>
                  {isWorkspace && workspaceId ? (
                    <WorkspacePageLayout workspaceId={workspaceId}>
                      {children}
                    </WorkspacePageLayout>
                  ) : (
                    children
                  )}
                </ErrorBoundary>
              )}
            </Stack>
          </Stack>
        </Stack>

        {isEmbedded && shouldShowPoweredBy && <PoweredByRibbon />}
      </Stack>
    </>
  );
};

export default RootLayout;
