import { useMemo } from 'react';
import { capitalize } from 'lodash';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  SxProps,
  Typography,
} from '@mui/material';

export interface FormFieldProps
  extends Omit<
    FormControlProps,
    'label' | 'helperText' | 'margin' | 'size' | 'color' | 'error'
  > {
  label?: string;
  subLabel?: string;
  errorMessage?: string; // replaces helperText
  labelOverflow?: boolean; // If true, label will not be truncated and overflow.
  action?: {
    actionLabel: string;
    onClick: () => void | Promise<void>;
  };
}

const LABEL_VERTICAL_SPACE = 22;
const SUB_LABEL_VERTICAL_SPACE = 22;

const FormField = (props: FormFieldProps) => {
  const {
    errorMessage,
    label,
    subLabel,
    labelOverflow,
    action,
    children,
    ...rest
  } = props;

  // helper text will be displayed with icon only if there is an errors
  const errorMessageWithIcon = useMemo(() => {
    return errorMessage ? (
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <ReportGmailerrorredIcon fontSize='small' sx={{ marginRight: 0.5 }} />
        {errorMessage}
      </span>
    ) : null;
  }, [errorMessage]);

  const treatedLabel = useMemo(() => {
    if (typeof label === 'string') {
      return capitalize(label);
    } else {
      return label;
    }
  }, [label]);

  const treatedSubLabel = useMemo(() => {
    return subLabel ? capitalize(subLabel) : subLabel;
  }, [subLabel]);

  const topSpacing = subLabel
    ? LABEL_VERTICAL_SPACE + SUB_LABEL_VERTICAL_SPACE
    : LABEL_VERTICAL_SPACE;

  const labelOverflowStyle: SxProps = labelOverflow
    ? {
        position: 'unset',
        whiteSpace: 'unset',
        textOverflow: 'unset',
        top: 'unset',
      }
    : {};

  return (
    <FormControl
      size='small'
      margin='dense'
      sx={{
        '& legend': { display: 'none' },
        '& fieldset': { top: 0 },
        paddingTop: labelOverflow ? 0 : `${topSpacing}px`,
      }}
      error={!!errorMessage}
      {...rest}
    >
      {label ? (
        <InputLabel
          shrink={true} // enables the placeholder to be visible when the input is empty
          disableAnimation={true}
          sx={{
            transform: 'translate(0px, 0px) scale(1)', // reset the label position from the MUI default
            fontSize: (theme) => theme.typography.h6.fontSize,
            fontWeight: (theme) => theme.typography.h6.fontWeight,
            marginBottom: 1,
            ...labelOverflowStyle,
          }}
        >
          {treatedLabel}
        </InputLabel>
      ) : null}
      {action && (
        <Typography
          variant='body2'
          onClick={action.onClick}
          color='text.secondary'
          sx={{
            cursor: 'pointer',
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        >
          {action.actionLabel}
        </Typography>
      )}
      {label && subLabel ? (
        // subLabel cannot be used without label
        // subLabel should not have required mark
        <InputLabel
          shrink={true} // enables the placeholder to be visible when the input is empty
          required={false}
          sx={{
            top: `${LABEL_VERTICAL_SPACE}px`,
            transform: 'translate(0px, 0px) scale(1)', // reset the label position from the MUI default
            fontSize: (theme) => theme.typography.body2.fontSize,
            fontWeight: (theme) => theme.typography.body2.fontWeight,
            marginBottom: 1,
            ...labelOverflowStyle,
          }}
        >
          {treatedSubLabel}
        </InputLabel>
      ) : null}
      {children}
      <FormHelperText sx={{ marginLeft: 0 }}>
        {errorMessageWithIcon}
      </FormHelperText>
    </FormControl>
  );
};

export default FormField;
